import React from 'react'
import { Toast } from 'react-bootstrap'

function ToastComponent(props) {
    const{show,setShow,responseData}=props
  return (
    <div className='justify-content-center'>
         <Toast onClose={() => setShow(false)} 
           show={show} delay={3000} autohide
            position="bottom-center"
          
            bg="info" >
              
          <Toast.Header >
           
            <strong className="me-auto"></strong>
           
          </Toast.Header>
          <Toast.Body>{responseData?responseData:"File Not Supported"}</Toast.Body>
        </Toast>
    </div>
  )
}

export default ToastComponent