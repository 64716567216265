import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/font-awesome/css/all.min.css";
import "./assets/css/config.css";
import "./assets/css/style.css";

import Home from "./Home";
import GlobalStateProvider from "./Context/storeProvider";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Form from "./Components/Form/Form";
import React, { useState } from "react";
import 'react-intl-tel-input/dist/main.css';



function App() {
  const [dataFromChild, setDataFromChild] = useState(false);

  // Callback function to receive data from the child
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  return (
    <>
    { dataFromChild ? 
    <>
      <Header/>
      <GlobalStateProvider>
        <Home/>
      </GlobalStateProvider>
      <Footer/>
    </> : <Form sendDataToParent={handleDataFromChild} /> }
    
    
    </>
  );
}

export default App;
