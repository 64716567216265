import React, { useCallback, useEffect, useState } from "react";
import { ManageApis } from "../../AppServices/ManageApi";
import TextInput from "../InputComponent/TextInput";
import Loader from "../SpinnerComponent/Loader";
import CaptchaGenerator from "../CaptchaComponent/Captcha";
import { formatingValue, originalCharacter, randomNum } from "../../Functions/Utils";
import { Col } from "react-bootstrap";
import IntlTelInput from 'react-intl-tel-input';

const Form = ({ sendDataToParent }) => {
  const [Disabled, setDisabled] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [code, setCode] = useState("");
  const [FormData, setFormData] = useState({
    name: "",
    email: "",
    mobile_number: "",
    state_code:"",
    company_name: "",
    message: "",
    captcha: "",
  });

  useEffect(() => {
  
    // const storedFormData = localStorage.getItem("FormData");
    // if (storedFormData) {
    //   setFormData(JSON.parse(storedFormData));
    // }
    generateCaptcha();
    return () => { 
      setFormData({
        name: "",
        email: "",
        mobile_number: "",
        state_code:"",
        company_name: "",
        message: "",
        captcha: "",
      })
    }
  }, []);

  const generateCaptcha = useCallback(async () => {
    let str = "";
    for (let i = 0; i < 4; i++) {
      const randomIndex = await randomNum(0, originalCharacter.length - 1);
      const temp = originalCharacter[randomIndex];
      str = `${str}${temp}`;
      // debugger;
      // console.log(`Iteration ${i}: Random Index = ${randomIndex}, Character = ${temp}`);
    }
    // console.log("Generated Captcha:", str);
    setCode(str);
  }, [randomNum, setCode]);

  const validateEmail = (text) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{3})+$/;
    return emailRegex.test(text);
  };
  // validate field and set error to state
  const handleError = (error, input) => {
    setErrors((prevState) => ({ ...prevState, [input]: error }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(FormData);
  };

  const validate = () => {
    debugger
    let isValid = true;
    if (!FormData.name) {
      handleError("Please enter your Name", "name");
      isValid = false;
    }
    if (!FormData.email) {
      handleError("Please enter your Email", "email");
      isValid = false;
    } else if (!validateEmail(FormData.email)) {
      handleError("Please enter a valid Email", "email");
      isValid = false;
    }
    if (!FormData.mobile_number) {
      handleError("Please enter your Mobile Number", "mobile_number");
      isValid = false;
    } else if (FormData.mobile_number.length > 10) {
      handleError("Please enter a valid Mobile Number", "mobile_number");
      isValid = false;
    }
    if (!FormData.company_name) {
      handleError("Please enter your Company Name", "company_name");
      isValid = false;
    }
    if (!FormData.message) {
      handleError("Please enter your a message", "message");
      isValid = false;
    }
    if (!FormData.captcha || FormData.captcha !== code) {
      handleError("Please enter the Captcha", "captcha");
      isValid = false;
    }
    return isValid;
  };
  const handleSubmit = async () => {
    if (validate()) {
      debugger  
      setisLoading(true);
      localStorage.setItem("FormData", JSON.stringify(FormData));

      let rqstBody = {
        firstName: FormData.name,
        email: FormData.email,
        phone: '+'+FormData.state_code+FormData.mobile_number,
        companyname: FormData.company_name,
        message: FormData.message,
      };
console.log(rqstBody)

      let urlpath = "https://app.pixl.ai/api/Pixl/CreateContact";

      let response = await ManageApis("post", urlpath, rqstBody);
      console.log("response", response);
      if (response.message === "Success") {
        setisLoading(false);
        console.log(response.response_data);
        sendDataToParent(true);
      } else {
        setisLoading(false);
        // console.log(response.response_data);
        alert(response.response_data);
        sendDataToParent(false);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <section className="form_outer_prop bg_master position-fixed w-100 h-100 start-0 top-0">
        <div className="position-relative zi_1 d-flex h-100 p-3 p-sm-4 container-md">
          <div className="m-auto pe-5 text-white max_w_50p d-none d-md-block font_14x">
            <h2 className="fw-semibold h3 pb-3">
              Transform Your <br />
              Enterprise Operations with Intelligent Document Processing
            </h2>
            <p>
              Use the sample data to perform test requests, observe the
              responses, and evaluate how our APIs can enhance your
              applications.
            </p>
            <p>
              <span className="pe-1">
                Should you have any questions or need assistance during your
                trial, our support team is ready to help. Reach out to us at
              </span>
              <a
                className="fw_600 text-decoration-none text-white link_hover"
                href="mailto:connect@pixdynamics.com"
              >
                connect@pixdynamics.com
              </a>
            </p>
            <p className="pt-2">
              <span className="pe-1">Don't have any files to test?</span>
              <a
                href="https://pixdynamics.com/contact-us"
                className="fw_600 text-decoration-none text-white link_hover"
              >
                Contact Us for a Demo
              </a>
            </p>
          </div>
          <div className="bg-white br_20x m-auto overflow-hidden py-3 px-1 py-sm-4  w-100 max_w_410x font_12x max_h_100p">
            <div className="custom_scroll1 overflow-auto px-2 px-sm-3 form_mainarea">
              <div className="pb-2 text-center pt-3">
                <h3 className="fw-bold h4">
                  Register to <br />
                  get free Trial Account
                </h3>
                <p>
                  Start exploring today with a free trial account! Register now
                  to unlock the benefits of our platform.
                </p>
              </div>
              <div className="pb-3">
                <div className="mb-3">
                  <label className="fw_500 pb-1">
                    Full name<span className="ps-1 text-danger">*</span>
                  </label>
                  <TextInput
                    type="text"
                    placeholder="Enter your Full Name"
                    value={FormData.name}
                    name={"name"}
                    onChange={(e) => handleChange(e)}
                    errors={errors.name}
                    onFocus={() => handleError(null, "name")}
                  />
                </div>
                <div className="mb-3">
                  <label className="fw_500 pb-1">
                    Email<span className="ps-1 text-danger">*</span>
                  </label>
                  <TextInput
                    type="text"
                    placeholder="Enter your Email"
                    value={FormData.email}
                    name={"email"}
                    onChange={(e) => handleChange(e)}
                    errors={errors.email}
                    onFocus={() => handleError(null, "email")}
                  />
                </div>
                <div className="mb-3">
                  <label className="fw_500 pb-1">
                    Mobile Number<span className="ps-1 text-danger">*</span>
                  </label>
                  <div className="CustomNumbBox">
                  <IntlTelInput
                    // countriesData={(e)=>{debugger;console.log(e)}}
                    
                    containerClassName="intl-tel-input"
                    inputClassName="form-control"
                    // defaultCountry="in"
                      // type="text"
                      placeholder="Enter Mobile Number"
                      defaultCountry="in"
                      value={FormData.mobile_number}
                      name={"mobile_number"}
                      onSelectFlag={(e,selectedCountryData)=>{setFormData((pre)=>({...pre,"state_code":selectedCountryData?.dialCode}))}}
                      // onFlagClick={(e)=>{debugger;console.log(e)}}
                      
                      onPhoneNumberChange={(e, dd, selectedCountryData) => { setFormData((pre) => ({ ...pre, "mobile_number": formatingValue(dd,10,/[^0-9\s]/g), "state_code": selectedCountryData?.dialCode })); handleError(null, "mobile_number")}}
                      // errors={errors.mobile_number}
                      // onFocus={() => handleError(null, "mobile_number")}
                      />
                  </div>
                  {errors.mobile_number && <span className="text-danger font_10x pt-1">{errors.mobile_number}</span>}
                  {/* <TextInput
                    type="number"
                    placeholder="Enter Mobile Number"
                    value={FormData.mobile_number}
                    name={"mobile_number"}
                    onChange={(e) => handleChange(e)}
                    errors={errors.mobile_number}
                    onFocus={() => handleError(null, "mobile_number")}
                  /> */}
                </div>
                <div className="mb-3">
                  <label className="fw_500 pb-1">
                    Company Name<span className="ps-1 text-danger">*</span>
                  </label>
                  <TextInput
                    type="text"
                    placeholder="Enter Company Name"
                    value={FormData.company_name}
                    name={"company_name"}
                    onChange={(e) => handleChange(e)}
                    errors={errors.company_name}
                    onFocus={() => handleError(null, "company_name")}
                  />
                </div>
                <div className="mb-3">
                  <label className="fw_500 pb-1">
                    Message<span className="ps-1 text-danger">*</span>
                  </label>
                  <TextInput
                    type="textarea"
                    value={FormData.message}
                    name={"message"}
                    placeholder="Enter message here.."
                    rows={3}
                    onChange={(e) => handleChange(e)}
                    errors={errors.message}
                    onFocus={() => handleError(null, "message")}
                  />
                </div>

                <div className="align-items-center d-md-flex gap-2 mb-3">
                  
                    <TextInput
                      type="text"
                      placeholder="Enter Captcha code"
                      value={FormData.captcha}
                      name={"captcha"}
                      onChange={(e) => handleChange(e)}
                      errors={errors.captcha}
                      maxlength={4}
                      onFocus={() => handleError(null, "captcha")}
                    />
                  
                  
                  <div class="align-items-center d-inline-flex justify-content-end ">
                      <CaptchaGenerator
                        generateCaptcha={generateCaptcha}
                        code={code}
                      />
                    
                  </div>
                </div>

                <div>
                  <button
                    className="btn btn-master px-5 w-100"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Form;
