import axios from "axios";

export const GET_API = async (params, path, headers) => {
  var config = { method: "get", url: path, headers: headers };
};

//post method calling function 
export const POST_API = async (data, headers, path) => {
  var config = {
    method: "post",
    url:  path,
    headers: headers,
    data: data,
  };

  // console.log("POST API config", config);

  let response = await axios.post(path,data,headers)
    .then(function (response) {
      // console.log("POST API response", response);
      return response;
    })
    .catch(function (error) {
      // console.log("POST API error", error);
      if (error.response) {
        return error.response;
      } else {
        return { status: 503,message:"NetWork Error !" };
      }
    });
  return ResponseValidation(response);
};


//api response validate function

export const ResponseValidation = (response) => {
  let status_code = null;
  let response_data = null;
  let message = null;
  if (response) {
    if (response.status) {
      // console.log("res status", response.status);
      if (response.status === 200) {
        message = "Success";
        status_code = response.status;
        response_data = response.data;
      } else if (response.status === 401) {
        // console.log({ response });
        message = "UnAutherized Invalid Key ! ";
        status_code = response.status;
        response_data = 0;
      } else if (response.status === 404) {
        message = "Page Not found";
        status_code = response.status;
        response_data = 0;
        // console.log(JSON.stringify(response_data));
      }
      else if (response.status === 406) {
        message = "No visible text in page";
        status_code = response.status;
        response_data = 0;
        // console.log(JSON.stringify(response_data));
      }
      else if (response.status === 403) {
        message = "Not Authenticated'";
        status_code = response.status;
        response_data = 0;
        // console.log(JSON.stringify(response_data));
      }
       else if (response.status === 500) {
        message = "Internal Server Error";
        status_code = response.status;
        response_data = 0;
     
      } else if (response.status === 503) {
        message = "Seems like server is not running";
        status_code = response.status;
        response_data = 0;
       
      } else {
        message = "Unhandled Condition met";
        response_data = 0;
        const response_message = JSON.stringify(response.data);
        
      }
      
      return {response_data,message,status_code};
    } else {
      message = "unexpected response got";
      // console.log("Response validation - No response status", response);
     
      return status_code;
    }
  }
};
