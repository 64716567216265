import React, { useEffect } from "react";

function TableComponet({ data, row, col }) {
  var TableHead = [];
  var TableData = [];

  // console.log({ data });
  TableHead = data!==undefined&&data.filter((word) => word["row_index"] === 0);
  // TableHead.push(result);
  // console.log("head",result);
  // result.forEach((arrayItem) => {
  //   // console.log("ww",arrayItem.text);
  //   TableHead.push({ item: arrayItem.text });
  // });
  for (var i = 1; i < row!==undefined&&row; i++) {
    var Tablecell = data!==undefined&&data.filter((z) => z["row_index"] == i);
    // console.log("row ", TableHead);

    TableData.push(Tablecell);
    var size = Object.keys(Tablecell).length;

    if (size != 0) {
      for (var j = 0; j < col!==undefined&&col; j++) {
        // console.log(row[j]["text"]);
        // const data = row[j]["text"];
      }
    }
  }

  // }))

  // const result = props.data.filter((word) => word["row_index"] === 0);

  // result.forEach((arrayItem) => {
  //   // console.log("ww",arrayItem.text);
  //   arr.push({ item: arrayItem.text });
  // });

  // // console.log("row", props.row);
  // // console.log("col", props.columns);

  // var ar = [];

  // for (var i = 1; i < props.row; i++) {
  //   var row = props.data.filter((z) => z["row_index"] == i);
  //   // console.log("row " + i, row);

  //   ar.push(row);
  //   var size = Object.keys(row).length;

  //   if (size != 0) {
  //     for (var j = 0; j < props.columns; j++) {
  //       // console.log(row[j]["text"]);
  //       // const data = row[j]["text"];
  //     }
  //   }
  // }
  return (
    <div className="table-responsive">
      <table className="table table-bordered border-collapse mb-0 font_14x">
        <thead className="table-light">
          <tr>
            {TableHead.map((data) => (
              <th>{data.text}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {TableData.map((element) => (
            <tr>
              {element.map((data) => (
                <td>{data.text}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableComponet;
