import React, { memo, useCallback, useEffect, useState } from "react";
import Captcha from "react-captcha-code";
// import { randomNum, originalCharacter } from "./utils";

// 随机数字

  
const CaptchaGenerator = ({generateCaptcha,code}) => {
 
  



  

  return (

    <div className="d-flex">
      <Captcha  code={code} className="h_35x w-100 " bgColor="#fff" />
    
      {/* <button onClick={generateCaptcha}>reRfresh</button> */}
      <i  onClick={generateCaptcha} className="fas fa-sync hover text_master cursor-pointer py-2 px-2 font_20"></i>
    </div>
  );
};

export default memo(CaptchaGenerator);