export const BaseUrl={
    "aadharmasking":"https://adhaar-nrfc.pixl.ai",
   "aadharreader":"https://pcs6.pixl.ai",
   "aadharreaderback":"https://pcs.pixl.ai",
    "chequereader":"https://ddextractor-cheque.pixl.ai",
    "ddreader":"https://ddextractor-cheque.pixl.ai",
    "ocr":"https://pcs6.pixl.ai",
    "ovdclasification":"https://ckyclegacy.pixl.ai",
   "panreader":"https://pcs6.pixl.ai",
    "passport":"https://pcs6.pixl.ai",
    "invoice":"https://layout.pixl.ai",
    "uploadData":"https://uat.pixl.ai",
    "voterid":"https://pcs6.pixl.ai",
    "nationalid":"https://pcs6.pixl.ai",
    "passportverification":"https://data-verification.pixl.ai",
    "panvarification":"https://data-verification.pixl.ai",
    "websiteUrl":"https://pixdynamics.com/",
    "productWebsiteurl":"https://products.pixl.ai"
}

export const headerKeys={
    "aadharmasking":'aasdapld7!!458856akspksiklsjj-sklsp@#sjkdkldi',
    "aadharreader":'6F98193E4EE2ACA99BB40E47AA4C33B36A408167621E1E543C1D508F77E89E4F',
    "aadharreaderback":'6F98193E4EE2ACA99BB40E47AA4C33B36A408167621E1E543C1D508F77E89E4F',
     "chequereader":'6F98193E4EE2ACA99BB40E47AA4C33B36A408167621E1E543C1D508F77E89E4F',
     "ddreader":'6F98193E4EE2ACA99BB40E47AA4C33B36A408167621E1E543C1D508F77E89E4F',
     "ocr":'6F98193E4EE2ACA99BB40E47AA4C33B36A408167621E1E543C1D508F77E89E4F',
     "ovdclasification":'3E3283CE94E85C2748A791C54961B264EACD8BEA292893DEAE34E980CBE8B102',
    "panreader":'6F98193E4EE2ACA99BB40E47AA4C33B36A408167621E1E543C1D508F77E89E4F',
     "passport":'6F98193E4EE2ACA99BB40E47AA4C33B36A408167621E1E543C1D508F77E89E4F',
     "passportback":'6F98193E4EE2ACA99BB40E47AA4C33B36A408167621E1E543C1D508F77E89E4F',
     "invoice":"17a2a4e39b9f359d272265fc2866584c",
     "voterid":"6F98193E4EE2ACA99BB40E47AA4C33B36A408167621E1E543C1D508F77E89E4F",
     "nationalId":"6F98193E4EE2ACA99BB40E47AA4C33B36A408167621E1E543C1D508F77E89E4F",
     "passportverification":"i234rndfssdfow3i234rn4dfow3i234rn34dfodfow3i234rn4dfow3i234rn34d3rnfsdfs48932",
     "panvarification":"aasdapld7!!458856akspksiklsjj-sklsp@#sjkdkldi",
}
