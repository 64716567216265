import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { Stack } from "react-bootstrap";
import { useStoreValues } from "../../Context/storeProvider";

function AlertBox({msg,show,handleCancelAlert}) {
    const { storeDetails, addStoreDetails } = useStoreValues();
    const { showAlertBox,exceptionMsg } = storeDetails;
   const handlecancel=()=>{
    addStoreDetails({showAlertBox:false,})
   }
   useEffect(() => {

    //  console.log(show)
   }, [show])
   
    return (  
        
      
        <Alert show={showAlertBox} className="px_alert alert_md alert_vcenter  ">
            
            <div className="px_alert_body rounded-3 w-100 m-auto max_h_100p overflow-auto bg-white ">
                <div className="p-3 pb-0 text-center">
                  
                    <p className="pt-3">{exceptionMsg} </p>
                </div>
                <div className="modal-footer bd_gray">
                    <Stack direction="horizontal" className="m-2 justify-content-center w-100" gap={2}>
                       
                        <button className="btn btn-master py-1" onClick={handlecancel} >Close</button>
                    </Stack>
                </div>
            </div>   
        </Alert>
    );
  }
  
  export default AlertBox;