import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
// import Spinner from 'react-bootstrap/Spinner';
// import Card from 'react-bootstrap/Card';

// import img from "./assets/images/sample_adhaar.png";
import NavSidebar from "./Components/SideBar/NavSidebar";
import UploadFileComponent from "./Components/UploadFilecomponent/UploadFileComponent";
import { Button, Card, Form, Spinner, Tabs } from "react-bootstrap";
import RetryButtonComponent from "./Components/RetryButtonComponent/RetryButtonComponent";
import ResponseComponent from "./Components/ResponseComponent/ResponseComponent";
import { useEffect, useState } from "react";
import Loader from "./Components/SpinnerComponent/Loader";
import TabComponent from "./Components/TabComponent/TabComponent";
import { useStoreValues } from "./Context/storeProvider";
import InputDataComponent from "./Components/InputComponent/InputDataComponent";
import AlertBox from "./Components/AlertBox/AlertBox";
function Home() {
  const {storeDetails, addStoreDetails} = useStoreValues();
  const {isLoading,IsUploaded,selectedMenu}=storeDetails;
  const onTabChange = async(key)=>{
    // console.log("reset",key)
     
         addStoreDetails({
          isLoading:false,
          IsUploaded:false,
          uploadFile:"",
          responseData:[],
          selectedMenu:key,
          selectedSide:"front",
          inputparam1:"",
          inputparam2:"",

        })
      }
  
  return (
    <>
      {isLoading && <Loader />}
      <AlertBox/>
      <Container className="py-4">
        <div className="text-center pb-4">
          <h1 className="font_28x fw-semibold">Online OCR software demo</h1>
          <p className="font_14x">
            {" "}
            Get started right away and enjoy the convenience of our OCR service
            today!
          </p>
        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="Aadhar Masking" onSelect={(key)=>onTabChange(key)}>
          <Row className="pt-2">
            <Col sm={3} className="pt-3 ocr_tabs_left">
              {/* side nav section */}
              <NavSidebar />
            </Col>
            <Col sm={9} className="pt-3  zi_n2">
              <Tab.Content>
                <Tab.Pane eventKey="Aadhar Masking" >
                  <div>
                    <h3 className="font_20x fw-semibold pb_10x with_line">
                      Aadhaar Masking
                    </h3>
                    <div>
                      {!IsUploaded ? (
                        <div className="position-relative pt-3 ">
                          <UploadFileComponent
                         
                          />
                        </div>
                      ) : (
                        <div className="">
                          {/* display response Data */}

                          <ResponseComponent />

                         
                        </div>
                      )}
                    </div>
                     {/* retry button component */}
                    <RetryButtonComponent/>

                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Aadhar Reader" >
                <h3 className="font_20x fw-semibold pb_10x with_line">
                     {selectedMenu}
                    </h3>
                      <TabComponent />

                </Tab.Pane>
                <Tab.Pane eventKey="Invoice Reader">
                 
                <div>
                    <h3 className="font_20x fw-semibold pb_10x with_line">
                     {selectedMenu}
                    </h3>
                    <div>
                      {!IsUploaded ? (
                        <div className="position-relative pt-3 ">
                          <UploadFileComponent
                         
                          />
                        </div>
                      ) : (
                        <div className="pt-3">
                          {/* display response Data */}

                          <ResponseComponent />

                         
                        </div>
                      )}
                    </div>
                     {/* retry button component */}
                    <RetryButtonComponent/>

                  </div>

                </Tab.Pane>
                <Tab.Pane eventKey="Passport Reader">
                <h3 className="font_20x fw-semibold pb_10x with_line">
                     {selectedMenu}
                    </h3>
                <TabComponent />
                </Tab.Pane>
                <Tab.Pane eventKey="Voter ID Reader"> 
                  {/* <TabComponent /> */}
                  <div>
                    <h3 className="font_20x fw-semibold pb_10x with_line">
                     {selectedMenu}
                    </h3>
                    <div>
                      {!IsUploaded ? (
                        <div className="position-relative pt-3 ">
                          <UploadFileComponent
                         
                          />
                        </div>
                      ) : (
                        <div className="">
                          {/* display response Data */}

                          <ResponseComponent />

                         
                        </div>
                      )}
                    </div>
                     {/* retry button component */}
                    <RetryButtonComponent/>

                  </div>
                  </Tab.Pane>
                <Tab.Pane eventKey="PAN Reader">
                <div>
                    <h3 className="font_20x fw-semibold pb_10x with_line">
                     {selectedMenu}
                    </h3>
                    <div>
                      {!IsUploaded ? (
                        <div className="position-relative pt-3 ">
                          <UploadFileComponent
                         
                          />
                        </div>
                      ) : (
                        <div className="">
                          {/* display response Data */}

                          <ResponseComponent />

                         
                        </div>
                      )}
                    </div>
                     {/* retry button component */}
                    <RetryButtonComponent/>

                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="National ID Reader">
                <div>
                    <h3 className="font_20x fw-semibold pb_10x with_line">
                     {selectedMenu}
                    </h3>
                    <div>
                      {!IsUploaded ? (
                        <div className="position-relative pt-3 ">
                          <UploadFileComponent
                         
                          />
                        </div>
                      ) : (
                        <div className="">
                          {/* display response Data */}

                          <ResponseComponent />

                         
                        </div>
                      )}
                    </div>
                     {/* retry button component */}
                    <RetryButtonComponent/>

                  </div>

                </Tab.Pane>
                <Tab.Pane eventKey="Passport Verification">
                <div>
                    <h3 className="font_20x fw-semibold pb_10x with_line">
                     {selectedMenu}
                    </h3>
                    <InputDataComponent
                         
                         />
                    
                     {/* retry button component */}
                    <RetryButtonComponent/>

                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Voter ID Verification">
                <div>
                    <h3 className="font_20x fw-semibold pb_10x with_line">
                     {selectedMenu}
                    </h3>
                    <InputDataComponent
                         
                         />
                    
                     {/* retry button component */}
                    <RetryButtonComponent/>

                  </div>
                </Tab.Pane>
             
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}

export default Home;
