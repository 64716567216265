import React from 'react'
import { Nav } from 'react-bootstrap'
import { navMenuList } from '../../ConfigFiles/content/globalData'
import { useStoreValues } from '../../Context/storeProvider';



function NavSidebar() {
  const {storeDetails, addStoreDetails} = useStoreValues();
 

  return (
    <div>
        <div className="ocr_tabs_outer custom_scroll1 border-end ps-1 pt-1">
                            <Nav variant="pills" className="flex-column ocr_tabs">
                               {navMenuList.map((item,index)=>{
                                return(
                                  
                                    <Nav.Item  className='pe-0 ps-0' key={index}>
                                    <Nav.Link eventKey={item.menuName}>
                                        <span className="tab_icon"><i className={item.icon}></i></span>
                                        <span className="tab_title ps-2 fw-semibold">{item.menuName}</span>
                                    </Nav.Link>
                                </Nav.Item>
                                )
                                
                               })
                               
                               }
                               
                            </Nav>
                        </div>
    </div>
  )
}

export default NavSidebar