import { createContext, useContext, useState } from "react";

const StoreContext= createContext();

const GlobalStateProvider = ({children})=>{
    const [uploadFile, setUploadFile] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [IsUploaded, setIsUploaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [storeDetails, setStoreDetails] = useState({
        uploadFile:"",
        inputparam1:"",
        inputparam2:"",
        showAlertBox:false,
        exceptionMsg:"",
        responseData:[],
        IsUploaded:false,
        isLoading:false,
        currentNavTab:"Aadhar Masking",
        selectedMenu:"Aadhar Masking",
        selectedSide:"front"
    })

    const addStoreDetails = details => {
        // setStoreDetails({...storeDetails, ...details});
        setStoreDetails(prevState => {
          return {...prevState, ...details};
        });
      };
    return(
        <StoreContext.Provider
        value={{
            storeDetails: storeDetails,
            addStoreDetails: addStoreDetails,
            uploadFile: uploadFile,
            setUploadFile: setUploadFile,
            responseData: responseData,
            setResponseData:setResponseData,
            setIsUploaded:setIsUploaded,
            IsUploaded: IsUploaded,
            isLoading:isLoading,
            setIsLoading:setIsLoading,
     
        }}>
        {children}
      </StoreContext.Provider>
    )
}
export const useStoreValues = () => useContext(StoreContext);

export default GlobalStateProvider;