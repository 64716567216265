import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useStoreValues } from "../../Context/storeProvider";

import TableComponet from "../TableComponent/TableComponet";

function ResponseComponent(props) {
  // const { uploadFile,responseData } = props;
  const { storeDetails, addStoreDetails } = useStoreValues();
  const { uploadFile, responseData, selectedMenu } = storeDetails;

  useEffect(() => {
    console.log("res", responseData);
  }, [responseData]);

  return (
    <div>
      <Row>
        <Col sm={6} className="mb-3">
          <Card className="bd_c2">
            <Card.Header className="border-bottom-0 text-center fw-semibold font_14x">
              <span className="py-1 d-inline-block">Uploaded Data</span>
            </Card.Header>
            <Card.Body>
              <Card.Img className="img_t1 w-100 hset1 " src={uploadFile} />
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} className="mb-3">
          {responseData.length != 0 && (
            <Card className="bd_c2 h-100 ">
              <Card.Header className="border-bottom-0 text-center fw-semibold font_14x">
                <span className="py-1 d-inline-block">Processed Data</span>
              </Card.Header>
              <Card.Body className="hset1 overflow-auto custom_scroll1 ">
                {selectedMenu === "Aadhar Masking" ? (
                  <Card.Img
                    className="img_t1 w-100 h-100"
                    style={{ maxHeight: "250px" }}
                    src={
                      responseData && `data:image/png;base64,${responseData}`
                    }
                  />
                ) : (
                  <>
                    {responseData[0].faceImage || responseData[0].signImage ? (
                      <div className="g-2 row">
                        {responseData[0].faceImage && (
                          <div className="col-6 mb-2">
                            <div className="border rounded text-center">
                              <img
                                className="card-img img_t1 m-2"
                                src={responseData[0].faceImage}
                                style={{ width: "100px", height: "100px" }}
                              />
                              <div className="bg-light font_12x fw-semibold p-1 rounded">
                                Face Image
                              </div>
                            </div>
                          </div>
                        )}

                        {responseData[0].signImage && (
                          <div className="col-6 mb-2">
                            <div className="border rounded text-center">
                              <img
                                className="card-img img_t1 m-2"
                                src={
                                  responseData[0].signImage &&
                                  responseData[0].signImage
                                }
                                style={{ width: "100px", height: "100px" }}
                              />
                              <div className="bg-light font_12x fw-semibold p-1 rounded">
                                Signature Image
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="table-responsive">
                      <table className="table table-bordered border-collapse mb-0 font_14x">
                        <thead className="table-light">
                          <tr>
                            <th>Label</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {responseData[0]?.data?.map((res) => {
                            // console.log(responseData.data);
                            // console.log("dd", res.key, res.value);
                            return (
                              <tr>
                                <td>{res.key}</td>
                                <td>{res.value}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
        {responseData[0]?.TableData?.map((data) => {
          console.log('table row --------->',data);
          return (
            <TableComponet data={data.data} row={data.row} col={data.col} />
          );
        })}
      </Row>
    </div>
  );
}

export default ResponseComponent;
