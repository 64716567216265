import React, { memo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import getBase64 from "../../AppServices/Base64Converter";
import {
  MenuwiseparamReturn,
  MenuwiseResponse,
} from "../../AppServices/Globalfunctions";
import { ManageApis } from "../../AppServices/ManageApi";
import { useStoreValues } from "../../Context/storeProvider";

import ToastComponent from "../ToastComponent/ToastComponent";

function UploadFileComponent() {
  const { storeDetails, addStoreDetails } = useStoreValues();
  const { selectedMenu, selectedSide, uploadFile } = storeDetails;
  const [show, setShow] = useState(false);

  const supportFormats = ["png", "jpeg", "jpg"];

  //handle upload file
  const handleUpload = async (e) => {
    addStoreDetails({ isLoading: true });

    // console.log("upload file", e.target.files[0]);
    const fileType = e.target.files[0].type.split("/").pop();
    if (supportFormats.includes(fileType)) {
      const base64String = await getBase64(e.target.files[0]);
      // console.log("uploabase64", base64String);
      addStoreDetails({ IsUploaded: true, uploadFile: base64String });
      // setUploadFile(base64String);

      // function  return params for menuwise
      // console.log("test", selectedMenu, selectedSide);
      let result = await MenuwiseparamReturn(
        selectedMenu,
        base64String,
        selectedSide
      );
      // console.log("menuwise response", result);
      //manage api call function
      let response = await ManageApis(
        "post",
        result.urlpath,
        result.rqstBody,
        result.header
      );

      if (response.message === "Success") {
        console.log("apiresponsedata", response);
        // setIsLoading(false)
        addStoreDetails({ isLoading: false });

        let responseData = await MenuwiseResponse(
          selectedMenu,
          response.response_data,
          selectedSide
        );
        console.log("final response", responseData);
        // setResponseData(responseData)
        addStoreDetails({  responseData: [responseData] });
      } else {
        addStoreDetails({
          isLoading: false,
          showAlertBox: true,
          exceptionMsg: response.message,
        });
      }
    } else {
      setShow(true);
    }
  };

  return (
    <>
      <div className="position-relative pt-3  ">
        <div className="upload_box d-flex align-items-center justify-content-center">
          <div className="d-grid text-center">
            <ToastComponent setShow={setShow} show={show} />
            <Form.Group>
              <Form.Control
                type="file"
                id="uploadFiles"
                className="visually-hidden"
                onChange={(e) => handleUpload(e)}
              />

              <Form.Label className="btn btn-master px-5" htmlFor="uploadFiles">
                Upload File
              </Form.Label>
            </Form.Group>
            <h5 className="fw_500 font_16x mt-1 mb-0 text-dark">
              Drop your file here or browse
            </h5>
          </div>
        </div>
      </div>
      <p className="font_12x pt-3 d-flex">
        <span className="text-danger pe-1 ">Note:</span>
        <span>
          Please ensure that the image you are uploading is clear and of good
          quality. The image should be in focus and free of any distortion or
          blur.
        </span>
      </p>
    </>
  );
}

export default memo(UploadFileComponent);
