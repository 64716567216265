export   const navMenuList=[{"menuName":"Aadhar Masking","icon":"far fa-id-card"},
{"menuName":"Aadhar Reader","icon":"fas fa-id-card"},
// {"menuName":"Invoice Reader","icon":"fas fa-receipt"},
{"menuName":"Passport Reader","icon":"fas fa-passport"},
{"menuName":"Voter ID Reader","icon":"fas fa-globe-americas"},
{"menuName":"PAN Reader","icon":"fas fa-id-card"},
// {"menuName":"National ID Reader","icon":"fas fa-file-signature"},
{"menuName":"Passport Verification","icon":"fas fa-globe"},
// {"menuName":"Voter ID Verification","icon":"fas fa-user"}
]

// export const passport_verificationInput_label={" Enter File Number"," Enter Date of Birth"}
