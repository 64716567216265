import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useStoreValues } from '../../Context/storeProvider';
import ResponseComponent from '../ResponseComponent/ResponseComponent'
import RetryButtonComponent from '../RetryButtonComponent/RetryButtonComponent';
import UploadFileComponent from '../UploadFilecomponent/UploadFileComponent'

function TabComponent() {
  const {storeDetails, addStoreDetails} = useStoreValues();
const {IsUploaded}=storeDetails;
const onSubmenuChange=(key)=>{
  addStoreDetails({
    isLoading:false,
    IsUploaded:false,
    uploadFile:"",
    responseData:[],
    selectedSide:key
  })
}
  return (
    <div>
    <div className=''>
         <Tabs
                    defaultActiveKey="front"
                    id=""
                className='nav_tab_t1 nav-pills'
                  onSelect={(key)=>onSubmenuChange(key)}
                    
                  >
                    <Tab eventKey="front" title="Front Side" className="nav-item nav-link" >
                    <div>
                      {!IsUploaded ? (
                        <div className="position-relative pt-3 ">
                          <UploadFileComponent
                            
                          />
                        </div>
                      ) : (
                        <div className=" pt-3">
                          {/* display response Data */}

                          <ResponseComponent />

                         
                        </div>
                      )}
                    </div>

                    </Tab>
                    <Tab eventKey="back" title="Back Side" className='nav-item nav-link'>
                    <div>
                      {!IsUploaded ? (
                        <div className="position-relative pt-3 ">
                          <UploadFileComponent/>
                        </div>
                      ) : (
                        <div className="pt-3">
                          {/* display response Data */}

                          <ResponseComponent />

                         
                        </div>
                      )}
                    </div>

                    </Tab>
                     
                  </Tabs>
                  </div>
                     {/* retry button component */}
                     <RetryButtonComponent/>
    </div>
  )
}

export default TabComponent