import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { useStoreValues } from "../../Context/storeProvider";

function RetryButtonComponent(props) {
 
  const {storeDetails, addStoreDetails} = useStoreValues();

  const handleReset=()=>{
    addStoreDetails({uploadFile:"",
                      responseData:[],
                      IsUploaded:false,
                      inputparam1:"",
                      inputparam2:"",
                    })

  }
  return (
    <div>
      <div className="border-top font_14x mt-3 pt-3 text-center">
        <span className="pe-3">Do you want to Retry?</span>
        <Button variant="master-line px-4 py-1" onClick={handleReset}>click here</Button>
      </div>
     
    </div>
  );
}

export default RetryButtonComponent;
