import moment from "moment/moment";
import React, { useState } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import {
  MenuwiseparamReturn,
  MenuwiseResponse,
} from "../../AppServices/Globalfunctions";
import { ManageApis } from "../../AppServices/ManageApi";
import { useStoreValues } from "../../Context/storeProvider";
import ToastComponent from "../ToastComponent/ToastComponent";

function InputDataComponent() {
  const { storeDetails, addStoreDetails } = useStoreValues();

  const { selectedMenu, selectedSide, inputparam1, inputparam2, responseData } =
    storeDetails;
  const [show, setShow] = useState(false);
  const handleSubmit = async () => {
    addStoreDetails({ isLoading: true });
    // console.log("test", selectedMenu, selectedSide);
    let params = { inputparam1, inputparam2 };

    let result = await MenuwiseparamReturn(selectedMenu, params);
    // console.log("varification res", result);
    //manage api call function
    let response = await ManageApis(
      "post",
      result.urlpath,
      result.rqstBody,
      result.header
    );

    if (response.message === "Success") {
      // console.log("apiresponsedata", response);
      // setIsLoading(false)
      addStoreDetails({ isLoading: false });

      let responseData = await MenuwiseResponse(
        selectedMenu,
        response.response_data
      );
      // console.log(responseData);
      // setResponseData(responseData)
      addStoreDetails({ responseData: responseData });
      setShow(true);
    } else {
      addStoreDetails({
        isLoading: false,
        showAlertBox: true,
        exceptionMsg: response.message,
      });
      // setIsLoading(false)
      // addStoreDetails({ isLoading:false})
    }
  };
  return (
    <>
      <div className=" pt-3 ">
        <div className="upload_box  align-items-center p-3 p-md-5 d-flex justify-content-center">
          <div style={{ maxWidth: "500px" }} className="w-100">
            <Row className="pb-2">
              <Col className="pb-2" md={6}>
                <label htmlFor="floatingInputCustom" className="">
                  {selectedMenu === "Passport Verification"
                    ? `Enter Passport File Number :`
                    : `Enter  VoterId :`}
                </label>
              </Col>
              <Col md={6} className="pb-2">
                <Form.Control
                  className=""
                  type="text"
                  value={inputparam1}
                  required
                  onChange={(e) =>
                    addStoreDetails({ inputparam1: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={6} className="pb-2">
                <label htmlFor="floatingInputCustom" className="">
                  {selectedMenu === "Passport Verification"
                    ? "Enter Date of Birth :"
                    : "Enter State :"}
                </label>
              </Col>
              <Col md={6} className="pb-2">
                <Form.Control
                  className=""
                  type={
                    selectedMenu === "Passport Verification" ? "date" : "text"
                  }
                  required
                  value={inputparam2}
                  onChange={(e) =>
                    addStoreDetails({ inputparam2: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className=" pt-4">
             { responseData.data&&<div class={`alert ${responseData.isValid?"alert-primary":"alert-danger"} text-center`} role="alert">
                {responseData.data}
              </div>}
              <Col md={12} className="text-center">
                <Button
                  variant="master-line px-4 px-md-5 py-1"
                  onClick={handleSubmit}
                >
                  Verify
                </Button>
              </Col>
            </Row>
            
          </div>
          {/* <div className=" mb-5 mt-4 d-flex justify-content-center">
                          <label htmlFor="floatingInputCustom" className='mt-2'>
                           {selectedMenu==="Passport Verification"?`Enter Passport File Number :`:`Enter  VoterId :`}
                          </label>
                          <Form.Control
                            className="w-50 mx-2"
                            type="text"
                            value={inputparam1}
                            required
                            onChange={(e) => addStoreDetails({inputparam1:e.target.value})}
                          />
                        </div>
                        <div className=" mb-5 mt-4 d-flex justify-content-center">
                          <label htmlFor="floatingInputCustom" className='mt-2 mx-4 pe-4 px-2'>
                            {selectedMenu==="Passport Verification"?"Enter Date of Birth :" :"Enter State :"}
                          </label>
                          <Form.Control
                            className=" mx-2 w-50"
                            type={selectedMenu==="Passport Verification"?"date":"text"}
                            required
                            value={inputparam2}
                            onChange={(e) =>  addStoreDetails({inputparam2:e.target.value})}
                          />
                        </div>
                        <div className=" d-flex justify-content-end mb-5 mt-4 mx-5 w-75">
                      
                        </div>
                        <div className='text-center'>

                       
                      
                        </div>
      */}
        </div>
      </div>
    </>
  );
}

export default InputDataComponent;
