import React from 'react'
import { Spinner } from 'react-bootstrap'

function Loader() {
  return (
    <div className="bg_white75p h-100 position-fixed px_loader top-0 start-0 w-100 loder_js" style={{zIndex:101}}>
    <div className="d-flex align-items-center justify-content-center h-100 w-100">
      <Spinner  animation="border" variant="master" />
    </div>
  </div>
  )
}

export default Loader