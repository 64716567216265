import Container from "react-bootstrap/Container";
import Navbar from 'react-bootstrap/Navbar';
import { Dropdown } from "react-bootstrap";
import img from "../../assets/images/pix_logo.png";

import "./Header.css"
import { BaseUrl } from "../../ConfigFiles/env/globelAPI";

const Header=()=> {
    
    return (
      <>
        
        <header className="top_header">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <span className="email_sec_top">
                        <i className="fas fa-envelope fs_12x pe-1" style={{color:'#00b3e3'}}></i>
                        <a href="mailto:connect@pixdynamics.com" className="fs_12x"  style={{color:'#5f5f5f'}} >connect@pixdynamics.com</a>
                    </span>
                    <div className="collapse d-flex flex-fill justify-content-end navbar-collapse">
                        <ul className="navbar-nav header align-items-center pt-0">
                            <li className="nav-item active">
                                <div className="btn-group li_have_submenu">
                                    
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="btn__04 w-100 px-2 border py_2x fs_12x language_btn">
                                        <i className="fas fa-globe mr-1"></i> Countries
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="fs_12x py-1 dropdown-menu-right language_dropdown position-absolute">
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}jp-ekyc-solution.html`}>Japanese</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}sg-digital-ekyc-solution.html`}>Singaporean</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}es-proveedores-de-soluciones-ekyc-espana.html`}>Spanish</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}ar-digital-ekyc-solution.html`}>Arabic</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}de-digital-ekyc-solution.html`}>German</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}au-digital-ekyc-solution.html`}>Australia</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}nz-digital-ekyc-solution.html`}>New Zealand</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}fr-digital-ekyc-solution.html`}>French</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}it-digital-ekyc-solution.html`}>Italian</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}sa-digital-ekyc-solution.html`}>South Africa</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}ca-digital-ekyc-solution.html`}>Canadian</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}arg-digital-ekyc-solution.html`}>Argentina</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}nra-digital-ekyc-solution.html`}>Nigeria</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}ke-digital-ekyc-solution.html`}>Kenya</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}us-digital-ekyc-solution.html`}>USA</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}gha-digital-ekyc-solution.html`}>Ghana</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}ug-digital-ekyc-solution.html`}>Uganda</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}cl-digital-ekyc-solution.html`}>Chile</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}nam-digital-ekyc-solution.html`}>Namibia</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}ban-ekyc-verification-solution.html`}>Bangladesh</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}swiss-ekyc-verification-solution.html`}>Switzerland</Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                    
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
        <header className="px_header">
            <Container>
          
                <Navbar   expand="lg">
                    <span className="logo__image">
                        <a href={BaseUrl.websiteUrl}><img className="px_head_logo" src={img} alt="PixDynamics logo"/></a>
                    </span>
                    <Navbar.Toggle aria-controls="navbarNav" />
                    <Navbar.Collapse id="navbarNav" className="justify-content-end">
                        <ul className="navbar-nav header align-items-center">
                        
                            <li className="nav-item active">
                                <div className="btn-group li_have_submenu">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="plain" className="w-100">
                                            Products
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}pixl-video-kyc-solution.html`}>Video KYC</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}ekyc-solution.html`}>eKYC Solution</Dropdown.Item>
                                            {/* <Dropdown.Item href={`${BaseUrl.websiteUrl}pixl-kyc-classifier-extractor.html`}>KYC classifier and Extractor</Dropdown.Item> */}
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}pixl-aadhar-card-masking-solution.html`}>Aadhaar Masking</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}pixl-face-recognition.html`}>Face Recognition</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}pixl-ocr-plus.html`}>OCR Solution</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}resume-parsing-solution.html`}>Resume Parser</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}pixl-document-text-reader.html`}>Document Reader</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}receipt-ocr-solution.html`}>Receipt OCR</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}pixl-invoice-reader.html`}>Invoice Reader</Dropdown.Item>
                                            <div className="item">
                                                <a className="dropdown-item" href={`${BaseUrl.websiteUrl}esign.html`}>eSign</a>
                                                <div className="position-relative">
                                                    <ul className="sb_menu_links rounded shadow-sm">
                                                        <li><a href={`${BaseUrl.websiteUrl}nesl-doc-sign.html`}>NeSL DDE Solution</a> </li>
                                                        <li><a href={`${BaseUrl.websiteUrl}nesl-e-bg-solution.html`}>NeSL e-BG Solution</a> </li>
                                                        <li><a href={`${BaseUrl.websiteUrl}aadhaar-esign.html`}>Aadhaar esign</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}virtual-sign.html`}>Virtual sign</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}image-compressor.html`}>Image Compressor</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}re-kyc-solution.html`}>RE-KYC Solution</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}ckyc-solution.html`}>cKYC Solution</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}dedupe.html.html`}>Dedupe</Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                    
                                </div>
                            </li>
                            <li className="nav-item active">
                                <div className="btn-group li_have_submenu">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="plain" className="w-100">
                                            Features
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}pixl-digital-kyc.html`}>Digital KYC</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}pixl-non-assisted-KYC.html`}>Non-Assisted KYC</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}pixl-video-kyc-interview.html`}>Video KYC Interview</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}pixl-kyc-verification-solution.html`}>KYC Verification Solution</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}mobile-ocr-sdk.html`}>Mobile OCR SDK</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li className="nav-item active">
                                <div className="btn-group li_have_submenu">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="plain" className="w-100">
                                            API Solution
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="pix_menu">
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}pixlai-e-sign-Verification.html`}>E-sign Verification API</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}pixlai-Aadhaar-Verification-API.html`}>Aadhaar Verification API</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}pixlai-International Passport OCR Reader.html`}>International Passport OCR Reader </Dropdown.Item>
                                                <div className="position-relative">
                                                    <ul className="sb_menu_links rounded shadow-sm px-0">
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-india.html`}>India</a> </li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-UAE.html`}>UAE</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-USA.html`}>USA</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Azerbaijan.html`}>Azerbaijan</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Nepal.html`}>Nepal</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Bangladesh.html`}>Bangladesh</a></li>

                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Bhutan.html`}>Bhutan</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Thailand.html`}>Thailand</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Sweden.html`}>Sweden</a></li>


                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Belgium.html`}>Belgium</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Brazil.html`}>Brasil</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Argentina.html`}>Argentina</a></li>

                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Australia.html`}>Australia</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Germany.html`}>Germany</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Japan.html`}>Japan</a></li>

                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-New Zealand.html`}>New Zealand</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-France.html`}>France</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Italy.html`}>Italy</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Austria.html`}>Austria</a></li>



                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Singapore.html`}>Singapore</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Uganda.html`}>Uganda</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Nigeria.html`}>Nigeria</a></li>

                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Croatia.html`}>Croatia</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Denmark.html`}>Denmark</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Canada.html`}>Canada</a></li>

                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Colombia.html`}>Colombia</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Finland.html`}>Finland</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Malaysia.html`}>Malaysia</a></li>
                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Norway.html`}>Norway</a></li>

                                                        <li><a href={`${BaseUrl.websiteUrl}passport-OCR-Russia.html`}>Russia</a></li>
                                                        




                                                        
                                                    </ul>
                                                </div>


                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}pixlai-voter-id-Verification-API.html`}>Voter Id Verification API</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}pixlai-PAN-Verification-API.html`}>PAN Verification API</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}pixlai-Cheque-Verification-API.html`}>Cheque Recognition Solution</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}pixlai-PIVC-solution.html`}>PIVC Solution</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}seaman-book-reader.html`}>Seaman's Book Reader</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}pixlai-aadhaar-offline-verification.html`}>Aadhaar Offline Verification</Dropdown.Item>
                                            </div>

                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}passport-verification-api.html`}>Passport Verification API</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}emirates-id-reader.html`}>Emirates ID OCR API</Dropdown.Item>
                                            </div>

                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}mexican-id-reader.html`}>Mexican ID OCR API</Dropdown.Item>
                                            </div>


                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}liveness-check-API.html`}>Liveness Check API</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}health-insurance-reader.html`}>Health Insurance Reader</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}invoice-annotation-service.html`}>Invoice Annotation</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}pan-ocr-api.html`}>PAN Card OCR API</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}driving-licence-ocr-api.html`}>Driving Licence OCR API</Dropdown.Item>
                                            </div>
                                            <div className="item">
                                                <Dropdown.Item href={`${BaseUrl.websiteUrl}credit-debit-card-ocr.html`}>Credit Debit Card OCR</Dropdown.Item>
                                            </div>
                                            
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li className="nav-item active">
                                <div className="btn-group li_have_submenu">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="plain" className="w-100">
                                            Company
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}industries.html`}>Industries</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}about-us.html`}>About Us</Dropdown.Item>
                                            <Dropdown.Item href={`${BaseUrl.websiteUrl}pixteam.html`}>Our Team</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            
                            
                            <li className="nav-item">
                                <a className="nav-link" href={`${BaseUrl.websiteUrl}blogs.html`}>Blogs</a>
                            </li>
                            <li className="nav-item">
                                <a href={`${BaseUrl.websiteUrl}contact-us.html`} className="btn__01 px-3 py-2 my-2 my-sm-0">Contact Us</a>
                            </li>
                        </ul>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </header>
      </>
    );
  }
  
  export default Header;