import React from "react";

function TextInput({
  label,
  type,
  onChange,
  placeholder,
  name,
  errors,
  onkeyDown,
  value,
  maxlength,
  onFocus,
  onkeyup,
  className,
  id,
  rows,
  disabled,
  max,
  isrequired,
  min,
  onkeypress

}) {


  return (
    <>
    <div>

    
    {
    type.includes('textarea') ? 
      <textarea 
      className={className?className:"form-control font_12x"}
      value={value}
      onChange={onChange} 
      onKeyDown={onkeyDown}
      onFocus={onFocus}
      disabled={disabled}
      placeholder={placeholder}
      name={name}
      id={id} 
      rows={rows}
      ></textarea>
    : 
      <input
      type={type?type:"text"}
      className={className?className:"form-control font_12x"}
      placeholder={placeholder}
      name={name}
      id={id}
      maxLength={maxlength}
      onChange={onChange}
      onKeyDown={onkeyDown}
      value={value}
      max={max}
      min={min}
      onFocus={onFocus}
      disabled={disabled}
      autoComplete="off"
      
    />
    }
    
      {errors && <span className="text-danger font_10x pt-1">{errors}</span>}
      </div>
    </>
  );
}

export default TextInput;
