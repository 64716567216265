import moment from "moment";
import { BaseUrl, headerKeys } from "../ConfigFiles/env/globelAPI";

export const MenuwiseparamReturn = async (type, data, selectedSide) => {
  let reqstData = null;
  let headers = null;
  let url = null;
  let path = null;
  // console.log("selected type", type, data, selectedSide);
  //aadharmaskng
  if (type === "Aadhar Masking") {
    // console.log(type);
    //baseurl
    url = BaseUrl.aadharmasking;
    path = "/api/v6/aadharMask";

    //request body
    reqstData = {
      aadhar_image: data,
      image_format: "png",
      image_uuid: "123",
      pdf_out: false,
    };
    //headers
    headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Key: headerKeys.aadharmasking,
      },
    };

    return { rqstBody: reqstData, header: headers, urlpath: url + path };
  }
  //aadharreader
  else if (type === "Aadhar Reader") {
    //baseurl
    url = BaseUrl.aadharreader;
    if (selectedSide === "front") {
      path = "/aadhaar/front";
      reqstData = {
        image: data,
        recordId: "123",
      };

      headers = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Key: headerKeys.aadharreader,
        },
      };
      return { rqstBody: reqstData, header: headers, urlpath: url + path };
    } else {
      path = "/aadhaar/back";
      reqstData = {
        image: data,
        recordId: "123",
      };

      headers = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Key: headerKeys.aadharreader,
        },
      };
      return { rqstBody: reqstData, header: headers, urlpath: url + path };
    }
  }
  ////passport reader
  else if (type === "Passport Reader") {
    //baseurl
    url = BaseUrl.aadharreader;
    headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Key: headerKeys.passport,
      },
    };
    if (selectedSide === "front") {
      path = "/passport/front";
      reqstData = {
        image: data,
        recordId: "123",
      };

      return { rqstBody: reqstData, header: headers, urlpath: url + path };
    } else {
      path = "/passport/back";
      reqstData = {
        image: data,
        recordId: "123",
      };

      return { rqstBody: reqstData, header: headers, urlpath: url + path };
    }
  }
  //pan reader
  else if (type === "PAN Reader") {
    //baseurl
    url = BaseUrl.panreader;
    path = "/pan1";
    reqstData = {
      image: data,
      recordId: "123",
    };

    headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Key: headerKeys.panreader,
      },
    };
    return { rqstBody: reqstData, header: headers, urlpath: url + path };
  }
  // passportvarification
  else if (type === "Passport Verification") {
    //baseurl
    url = BaseUrl.passportverification;
    path = "/passport_verification";
    reqstData = {
      fileno: data.inputparam1,
      dob: moment(data.inputparam2).format("DD/MM/YYYY"),
    };

    headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Key: headerKeys.passportverification,
      },
    };
    return { rqstBody: reqstData, header: headers, urlpath: url + path };
  }
  //   pan varification
  else if (type === "PAN Verification") {
    //baseurl
    url = BaseUrl.panvarification;
    path = "/electoralsearch";
    reqstData = {
      voterId: data.inputparam1,
      state: data.inputparam2,
    };

    headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Key: headerKeys.panvarification,
      },
    };
    return { rqstBody: reqstData, header: headers, urlpath: url + path };
  }
  //voterid
  else if (type === "Voter ID Reader") {
    //baseurl
    url = BaseUrl.voterid;
    headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Key: headerKeys.voterid,
      },
    };

    path = "/voterIdFront";
    reqstData = {
      image: data,
      recordId: "123",
    };

    return { rqstBody: reqstData, header: headers, urlpath: url + path };

    //  }else{
    //     path="/voterIdBack"
    //     reqstData = {
    //         image: data,
    //         recordId: '123',
    //       };
    //         return  {"rqstBody":reqstData,"header":headers,urlpath:url+path}
    //  }
  } else if (type === "Invoice Reader") {
    //baseurl
    url = BaseUrl.invoice;
    headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Key: headerKeys.invoice,
      },
    };
    path = "/wrapperAPI";

    reqstData = {
      image: data,
      recordId: "123",
    };

    return { rqstBody: reqstData, header: headers, urlpath: url + path };
  }

  // national id
  else if (type === "National ID Reader") {
    //baseurl
    url = BaseUrl.nationalid;
    path = "/nationalId";
    reqstData = {
      image: data,
      recordId: "123",
    };

    headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Key: headerKeys.nationalId,
      },
    };
    return { rqstBody: reqstData, header: headers, urlpath: url + path };
  }
  //invoice reader
  else if (type === "Invoice Reader") {
    //baseurl
    url = BaseUrl.invoice;
    path = "/wrapperAPI";
    reqstData = {
      image: data,
      recordId: "123",
    };

    headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Key: headerKeys.invoice,
      },
    };
    return { rqstBody: reqstData, header: headers, urlpath: url + path };
  } else {
    return 0;
  }
};

export const MenuwiseResponse = async (menu, result, side) => {
  let response = null;
  let faceImage = null;
  let signImage = null;
  // console.log("selectmenu", menu, result);
  if (menu === "Aadhar Masking") {
    response = result.detail.response_image;
    return response;
  } else if (menu === "Aadhar Reader") {
    response = side === "front" ? result.entities : result;
    faceImage = side === "front" ? result.faceImage : null;
    const formateData = objIterate(response);
    // console.log("iterate response", formateData);
    if (result.face_detection_status === "SUCCESS") {
      return { data: formateData, faceImage: faceImage };
    } else {
      return { data: formateData };
    }
  } else if (menu === "PAN Reader") {
    response = result.data;
    faceImage = result.faceImage;
    signImage = result.signImage;
    const formateData = objIterate(response);
    // console.log("iterate response", formateData);
    return { data: formateData, faceImage: faceImage, signImage: signImage };
  } else if (menu === "Passport Verification") {
    response = result.validity;
    // console.log("isvalid",result.validity)
    if (response === "not_valid") {
      return {
        data: ` Your Passport is ${response.replace("_", " ")}`,
        isValid: false,
      };
    } else {
      return {
        data: ` Your Passport is ${response.replace("_", " ")}`,
        isValid: true,
      };
    }
  } else if (menu === "Voter ID Verification") {
    response = result.validity;
    if ((response = "not_valid")) {
      return {
        data: ` Your Voter ID is ${response.replace("_", " ")}`,
        isValid: false,
      };
    } else {
      return {
        data: ` Your Voter ID is ${response.replace("_", " ")}`,
        isValid: true,
      };
    }
  } else if (menu === "Voter ID Reader") {
    response = result.data;
    const formateData = objIterate(response);
    // console.log(formateData);
    let data = { data: formateData };
    // console.log(data);
    return data;
  } else if (menu === "Passport Reader") {
    response = result.data;
    faceImage = `${side === "front" ? result.faceImage : null}`;
    const formateData = objIterate(response);
    // console.log("iterate response", formateData);
    if (side === "front") {
      return { data: formateData, faceImage: faceImage };
    } else {
      return { data: formateData };
    }
  } else if (menu === "Invoice Reader") {
    response = result.layout_dict;
    let TableData = response[0].Tables;
    let formateData = [];
    let extractData = response[1].Structure_Results.key_value_pair;
    const formatedTableData = TableData.map((data) => {
      // // console.log(
      //   response,
      //   TableData,
      //   JSON.parse(data).table_json.columns,
      //   JSON.parse(data).table_json.rows,
      //   JSON.parse(data).table_json.cell
      // );

      // formateData.push({
      //   row: JSON.parse(data).table_json.rows,
      //   col: JSON.parse(data).table_json.columns,
      //   data: JSON.parse(data).table_json.cell,
      // });
      // (JSON.parse(data) ?
      // )
      console.log(data.find((itm) => itm.includes("No visible text in page")));
      if (!data.includes("No visible text in page")) {
        return {
          row: JSON.parse(data)?.table_json?.rows,
          col: JSON.parse(data)?.table_json?.columns,
          data: JSON.parse(data)?.table_json?.cell,
        };
      }
    });

    return { data: extractData, TableData: formatedTableData };
  }
  // national id
  else if (menu === "National ID Reader") {
    response = result.data;

    const formateData = objIterate(response);
    // console.log("iterate response", formateData);
    return { data: formateData };
  }
};
//object itration
export const objIterate = (data) => {
  // console.log({ data });
  let IterateData = [];
  for (let key in data) {
    // console.log(n, data[n]);
    if (key !== "recordId") {
      if (key !== "retrievalStatus") {
        IterateData.push({ key: handleCapitalize(key), value: data[key] });
        // console.log(`${key}: ${value}`);
      }
    }
  }

  return IterateData;
};

//capitalize string
export const handleCapitalize = (value) => {
  const capitalized = value.charAt(0).toUpperCase() + value.slice(1);
  return capitalized;
};
