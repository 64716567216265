
import img from "../../assets/images/pixdynamics white.png";
import { BaseUrl } from "../../ConfigFiles/env/globelAPI";
import FBlogo from "../../assets/images/fbLogo.jpeg";
import twitLogo from "../../assets/images/twiterLogo.jpeg";
import linkedIn from "../../assets/images/linkedinLog.jpeg";



import "./Footer.css"

const  Footer=()=> {
    return (
      <>
        <footer>
			<div>
                <div className="container-fluid footer_rectangle ">
                    <div className="container footer_section position-relative">
                        <div className="row pb-5">
                        <div className="col-md-12 col-lg-4">
				<div className="footer_logo" >
						<a href={BaseUrl.websiteUrl}><img className="px_foot_logo" src={img}/></a>
				</div>
				<div className="footer_heading mb-4  " >
					We Are Passionate About Our Work and Inspired By Pixl Technology
				</div>	
				<div className="footer_cs footer_contact  " style={{fontSize: "14px"}}>
					<h5 className="title">CONTACT US</h5>
					<div class="pb-2">
						<h6 class="fs_18x fw_700 mb-3">For Sales enquiries</h6>
						<ul class="menu_links">
							<li class=""><a href="tel:+917994433594">Phone: +91 7994433594</a> </li>
							<li> <a href="mailto:connect@pixdynamics.com">Email us: connect@pixdynamics.com</a></li>					
						</ul>
					</div>
					<ul className="menu_links ">
						<h6 class="fs_18x fw_700 mb-3">For HR enquiries</h6>
						<li><a href="tel:+917994433594">Phone: +91 7994433594</a> </li>
						<li> <a href="mailto:connect@pixdynamics.com">Email us: mary@pixdynamics.com</a></li>					
					</ul>
					<ul class="menu_links">	
						<li><h6 class="fs_18x fw_700 mb-3">CORPORATE OFFICE</h6></li>
						<li>lll A - 2, Phase 2, Carnival Infopark
							 Kochi, Kerala, India, 682030</li>
						<li><h6 class="fs_18x fw_700 mb-3">SALES & SUPPORT</h6></li>
						<li>WeWork Salarpuria Symbiosis Venugopal Reddy Layout, Arekere, Bangalore, Karnataka 560076</li>						
					</ul>
				</div>
			</div>
                            <div className="col-md-12  col-lg-7">
                                <div className="row footer_content">
                                  <div className="footer_cs col-md"  style={{fontSize: "14px"}}>
						<h5 className="title">PRODUCTS</h5>
						<ul className="menu_links">
							<li><a href={`${BaseUrl.websiteUrl}pixl-video-kyc-solution.html`}>Video KYC</a></li>
							<li><a href={`${BaseUrl.websiteUrl}ekyc-solution.html`}>eKYC Solution</a></li>
							{/* <li><a href={`${BaseUrl.websiteUrl}pixl-kyc-classifier-extractor.html`}>KYC Classifier & Extractor</a> </li> */}
							<li><a href={`${BaseUrl.websiteUrl}pixl-aadhar-card-masking-solution.html`}>Aadhaar Card Masker</a></li>
							<li><a href={`${BaseUrl.websiteUrl}pixl-face-recognition.html`}>Face Recognition</a></li>
							<li><a href={`${BaseUrl.websiteUrl}pixl-document-text-reader.html`}>Document Reader</a></li>
							<li><a href={`${BaseUrl.websiteUrl}pixl-ocr-plus.html`}>OCR Solution</a></li>
							<li><a href={`${BaseUrl.websiteUrl}esign.html`}>eSign</a></li>
							{/* <li><a href={`${BaseUrl.websiteUrl}pixl-invoice-reader.html`}>Invoice Reader</a></li> */}
							<li><a href={`${BaseUrl.websiteUrl}pixl-non-assisted-KYC.html`}>Non-Assisted KYC</a></li>
							<li><a href={`${BaseUrl.websiteUrl}nesl-doc-sign.html`}>NeSL DDE Solution</a></li> 
							<li><a href={`${BaseUrl.websiteUrl}nesl-e-bg-solution.html`}>NeSL e-BG Solution</a></li> 
							<li><a href={`${BaseUrl.websiteUrl}aadhaar-esign.html`}>Aadhaar esign</a></li>
							<li><a href={`${BaseUrl.websiteUrl}virtual-sign.html`}>Virtual sign</a></li>
							<li><a href={`${BaseUrl.websiteUrl}image-compressor.html`}>Image Compressor</a></li>
							<li><a href={`${BaseUrl.websiteUrl}re-kyc-solution.html`}>RE-KYC Solution</a></li>
							<li><a href={`${BaseUrl.websiteUrl}receipt-ocr-solution.html`}>Receipt OCR Solution</a></li>
							<li><a href={`${BaseUrl.websiteUrl}number-plate-recognition-and-extraction.html`}>Number Plate Recognition</a></li>
							<li><a href={`${BaseUrl.websiteUrl}dedupe.html`}>Deduplication</a></li>
						</ul>
					</div>
									
									
									<div className="footer_cs col-md" style={{fontSize: "14px"}}>
						<h5 className="title">API Solution</h5>
						<ul className="menu_links">
							<li>
								<a href={`${BaseUrl.websiteUrl}pixlai-voter-id-Verification-API.html`}>Voter Id Verification API</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}pixlai-PAN-Verification-API.html`}>PAN Verification API</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}pixlai-Cheque-Verification-API.html`}>Cheque Recognition Solution</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}pixlai-PIVC-solution.html`}>PIVC Solution</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}seaman-book-reader.html`}>Seaman's Book Reader</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}pixlai-aadhaar-offline-verification.html`}>Aadhaar Offline Verification</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}pixlai-Passport-Verification-and-OCR-Reader-API.html`}>Passport Verification & OCR Reader</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}emirates-id-reader.html`}>Emirates ID OCR API</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}mexican-id-reader.html`}>Mexican ID OCR API</a>
							</li>   
							<li>
								<a href={`${BaseUrl.websiteUrl}liveness-check-API.html`}>Liveness Check API</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}pixl-invoice-reader.html`}>Invoice Reader</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}health-insurance-reader.html`}>Health Insurance Reader</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}invoice-annotation-service.html`}>Invoice Annotation</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}pan-ocr-api.html`}>PAN Card OCR API</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}driving-licence-ocr-api.html`}>Driving Licence OCR API</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}test-certificate-ocr.html`}>Test Certificate OCR</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}medical-prescription-ocr.html`}>Medical Prescription OCR</a>
							</li>
							<li>
								<a href={`${BaseUrl.websiteUrl}business-card-ocr.html`}>Business Card OCR</a>
							</li>
						</ul>
					</div>
                                    <div className="footer_cs col-md" style={{fontSize: "14px"}}>
                                        <h5 className="title">NAVIGATE</h5>
                                        <ul className="menu_links">
                                            <li><a href={`${BaseUrl.websiteUrl}index.html`}>Home</a></li>
                                            <li><a href={`${BaseUrl.websiteUrl}industries.html`}>Industries</a></li>
                                            <li><a href={`${BaseUrl.websiteUrl}blogs.html`}>Blogs</a></li>
                                            <li><a href={`${BaseUrl.websiteUrl}about-us.html`}>About Us</a></li>
                                            <li><a href={`${BaseUrl.websiteUrl}contact-us.html`}>Contact Us</a></li>
                                            <li><a href={`${BaseUrl.websiteUrl}career.html`}>Career</a></li>
                                            <li><a href={`${BaseUrl.websiteUrl}case-studies.html`}>Case Studies</a></li>
                                           
                                            <li><a href={`${BaseUrl.websiteUrl}cookie-policy.html`}>Cookie Policy</a></li>
                                        </ul>
                                    </div>
								
                                   
                                </div>
                            </div>			
                        </div>	
                        <div className="footer_social" style={{paddingTop: "10px",float:"right"}}>
			<a href="https://www.facebook.com/pixdyn"><img className="rounded-circle shadow-sm social-media" src={FBlogo}/></a>
			<a href="https://www.linkedin.com/company/pixdynamics/mycompany/?viewAsMember=true"><img className="rounded-circle shadow-sm social-media" src={linkedIn}/></a>
			<a href="https://twitter.com/pix_dynamics"><img className="rounded-circle shadow-sm social-media" src={twitLogo}/></a>
		</div>
		<div className="footer_text" style={{color: "#fff"}}>© 2023 PixDynamics  All rights reserved. Privacy Policy</div>		
                    </div>
                </div>
            </div> 
		</footer>
      </>
    );
  }
  
  export default Footer;