import { POST_API } from "./APIS";

export const ManageApis = async (method, path, params, headers) => {

  if (method === "post") {
    // console.log("apicall params",method,path,params,headers)
    let results = POST_API(params, headers, path);
    return results.then((results)=>{
        if(results.status_code){
          // console.log("manageapiresponse",results)
            return results;

        }
        else{
            return results.message;
        }
    });
  }
};
