// function for captcha
// Function to generate a random number between m and n, inclusive
export async function randomNum(m, n) {
    // debugger
    const crypto = window.crypto || window.msCrypto; // Check for browser compatibility
    if (crypto) {
      const array = new Uint32Array(1);
      await crypto.getRandomValues(array);
      const randomValue = array[0] / 0xFFFFFFFF; // Normalize to a floating-point number between 0 and 1
      return Math.floor(randomValue * (n - m + 1) + m);
    } else {
      // Fallback to 1 if Web Crypto API is not available
      return 1
    }
  }
  
  // Function to generate a random color
  export async function randomColor() {
    return `rgb(${await randomNum(0, 255)}, ${await randomNum(0, 255)}, ${await randomNum(0, 255)})`;
  }
  
  // for captcha
  export const originalCharacter = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  export const formatingValue = (value,max = 10,regx = /[^a-zA-Z0-9\s]/g ) => value.replace(regx, '').slice(0, max).trimStart()